import React, { useEffect } from 'react'
import { MainLayout } from '../../../layout'
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import images from '../../../assets/images';
import { getArticles, reset as areset } from '../../../../redux/feature/annonce/annonce.slice';
import { getEvents, reset as ereset } from '../../../../redux/feature/event/event.slice';
import { getPosts, reset as preset } from '../../../../redux/feature/post/post.slice';
import { getPubs, reset } from '../../../../redux/feature/pub/pub.slice';
import { Button } from '../../../components';
import { Link, useNavigate } from 'react-router-dom';
import { links } from '../../../routes';
import { capitalize, formatMyDate, formatNumber, formatNumber2, truncate } from '../../../../utils/function';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import { addDays, format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { getPartners } from '../../../../redux/feature/partner/partner.slice';

interface SimpleBtn {
  onClick?: (val?: any) => any | void,
  className?: any,
}
export const SliderNextArrow:React.FC<SimpleBtn> = ({onClick, className}) => {
  return (
      <div className={`enka-arrow ${className}`} id='next' onClick={onClick} >
        <i className="icon-interface-arrows-left-circle-1--arrow-keyboard-circle-button-left"></i>
      </div>
  );
};

export const SliderPrevArrow:React.FC<SimpleBtn>  = ({onClick, className}) => {
  return (
      <div className={`enka-arrow ${className}`} id='prev' onClick={onClick} >
          <i className="icon-interface-arrows-left-circle-1--arrow-keyboard-circle-button-left"></i>
      </div>
  );
};


const FavorieEvents = () => {
  const {isLoading, isError, isSuccess, events, message} = useAppSelector((state) => state.event);
  let setting = {
    dot: false,
    infinite: events?.filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date())?.filter((el: any) => el.epingle)?.length > 4 ? true : false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    cssEase: 'linear',
    autoplay: events?.filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date())?.filter((el: any) => el.epingle)?.length > 4 ? true : false,
    autoplaySpeed: 2000,
    prevArrow: events?.filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date())?.filter((el: any) => el.epingle)?.length > 4 ? <SliderPrevArrow/> : <></>,
    nextArrow: events?.filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date())?.filter((el: any) => el.epingle)?.length > 4 ?  <SliderNextArrow/> : <></>,
  }
  let navigate = useNavigate()
  
  return (
    <>
    {
      isLoading ? (
        <><i className="icon-spin"></i> <span>Chargement...</span></>
      ) : (
        isError ? (
          <><i className="icon-warning-triangle"></i> <strong>{message}</strong> <button type='button' style={{textDecoration: "underline", opacity: .8}} onClick={() => window?.location?.reload()}>Reactualiser...</button></>
        ) : (
          isSuccess && events ? (
            events?.filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date())?.filter((el: any) => el.epingle)?.length > 0 ? (
              <div className='enka-home-slide-section'>
                <div className='enka-home-slide-section__header enka-home-slide-section-header'>
                  <h2>Evènements epinglés</h2>
                  <div className='enka-home-slide-section-header__action'>
                    <Link to={links.events + "?type=epingle"} className='kubini-link'>
                      <span>Voir plus</span>
                    </Link>
                  </div>
                </div>
                <div className='enka-home-slide-section__container'>
                  <Slider {...setting}>
                    {
                      events?.filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date())?.filter((el: any) => el.epingle).slice(0, 8).map((item: any, index: number) => (
                        <div className='enka-home-slide-item' key={index}>
                        <button className='enka-card' type='button' onClick={() => navigate(links.event + item?.slug)}>
                          {
                            item.epingle ? (
                              <div className='enka-card__important'>
                                <i className="icon-pin-2"></i>
                              </div>
                            ) : null
                          }
                              <div className='enka-card-image'>
                                {
                                  item?.images?.length > 0 ? (
                                    item?.images[0]?.url ? (
                                      <img src={item?.images[0]?.url} alt="cover" />
                                    ) : (
                                      <i className="icon-picture-flower"></i>
                                    )
                                  ) : (
                                    <i className="icon-picture-flower"></i>
                                  )
                                }
                              </div>
                              <div className='enka-card-info'>
                                <div className='enka-card-info-id'>
                                  <strong>{item?.title ? truncate(item?.title, 80) : "ND"}</strong>
                                  <h2>{item?.date_event ? capitalize(format(new Date(item?.date_event), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}</h2>
                                  
                                  <span>{"Lieu :" + item?.lieu?.toUpperCase()}</span> 
                                </div>

                                <div className='enka-card-info-stats'>
                                  <div className='enka-card-info-user'>
                                    <div className='enka-card-info-user__avatar'>
                                    {
                                      item?.user?.auth?.avatar ? (
                                        <img src={item?.user?.auth?.avatar?.url} alt="" />
                                      ) : (
                                        <i className="icon-user-single"></i>
                                      )
                                    }
                                    </div>
                                    <div className='enka-card-info-user__info'>
                                      <strong>{item?.user?.auth?.fullname}</strong>
                                      <span>{item?.user?.city?.name || ""}</span>
                                    </div>
                                  </div>
                                  <div className='enka-card-info-stat'>
                                    <div className='enka-card-info-stat__item'>
                                      <i className="icon-favorite-heart"></i> <span>{item?.likes?.length > 0 ? formatNumber(item?.likes?.length) : "Aucun"} utilisateur(s) aime(nt)</span>
                                    </div>
                                    <div className='enka-card-info-stat__item'>
                                      <i className="icon-chat-two-bubbles-oval"></i> <span>{item?.comments?.length > 0 ? formatNumber(item?.comments?.length) : "Aucun"} commentaire(s)</span>
                                    </div>
                                  </div>
                                  <span className='enka-card-info-stats__action'>Publiée {formatMyDate(item?.createdAt)}</span>
                                </div>
                              </div>
                            </button>
                      </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>
            ) : null
          ) : null
        )
      )
    }
    </>
  )
}
const RecentEvents = () => {
  const {isLoading, isError, isSuccess, events, message} = useAppSelector((state) => state.event);
  
  let setting = {
    dot: false,
    infinite: events?.filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date()).length > 4 ? true : false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    cssEase: 'linear',
    autoplay: events?.filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date()).length > 4 ? true : false,
    autoplaySpeed: 2000,
    prevArrow: events?.filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date()).length > 4 ? <SliderPrevArrow/> : <></>,
    nextArrow: events?.filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date()).length > 4 ?  <SliderNextArrow/> : <></>,
 
  }
  let navigate = useNavigate()
  return (
    <>
    {
      isLoading ? (
        <><i className="icon-spin"></i> <span>Chargement...</span></>
      ) : (
        isError ? (
          <><i className="icon-warning-triangle"></i> <strong>{message}</strong> <button type='button' style={{textDecoration: "underline", opacity: .8}} onClick={() => window?.location?.reload()}>Reactualiser...</button></>
        ) : (
          isSuccess && events ? (
            events?.filter(ev => addDays(parseISO(`${ev.date_event}`), 1) > new Date())?.length > 0 ? (
              <div className='enka-home-slide-section'>
                <div className='enka-home-slide-section__header enka-home-slide-section-header'>
                  <h2>Evènements à venir</h2>
                  <div className='enka-home-slide-section-header__action'>
                    <Link to={links.events} className='kubini-link'>
                      <span>Voir plus</span>
                    </Link>
                  </div>
                </div>
                <div className='enka-home-slide-section__container'>
                  <Slider {...setting}>
                    {
                      events?.filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date())?.slice(0, 8).map((item: any, index: number) => (
                        <div className='enka-home-slide-item' key={index}>
                          <button className='enka-card' type='button' onClick={() => navigate(links.event + item?.slug)}>
                            {
                              item.epingle ? (
                                <div className='enka-card__important'>
                                  <i className="icon-pin-2"></i>
                                </div>
                              ) : null
                            }
                              <div className='enka-card-image'>
                                {
                                  item?.images?.length > 0 ? (
                                    item?.images[0]?.url ? (
                                      <img src={item?.images[0]?.url} alt="cover" />
                                    ) : (
                                      <i className="icon-picture-flower"></i>
                                    )
                                  ) : (
                                    <i className="icon-picture-flower"></i>
                                  )
                                }
                              </div>
                              <div className='enka-card-info'>
                                <div className='enka-card-info-id'>
                                  <strong>{item?.title ? truncate(item?.title, 80) : "ND"}</strong>
                                  <h2>{item?.date_event ? capitalize(format(new Date(item?.date_event), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}</h2>
                                  
                                  <span>{"Lieu :" + item?.lieu?.toUpperCase()}</span> 
                                </div>

                                <div className='enka-card-info-stats'>
                                  <div className='enka-card-info-user'>
                                    <div className='enka-card-info-user__avatar'>
                                    {
                                      item?.user?.auth?.avatar ? (
                                        <img src={item?.user?.auth?.avatar?.url} alt="" />
                                      ) : (
                                        <i className="icon-user-single"></i>
                                      )
                                    }
                                    </div>
                                    <div className='enka-card-info-user__info'>
                                      <strong>{item?.user?.auth?.fullname}</strong>
                                      <span>{item?.user?.city?.name || ""}</span>
                                    </div>
                                  </div>
                                  <div className='enka-card-info-stat'>
                                    <div className='enka-card-info-stat__item'>
                                      <i className="icon-favorite-heart"></i> <span>{item?.likes?.length > 0 ? formatNumber(item?.likes?.length) : "Aucun"} utilisateur(s) aime(nt)</span>
                                    </div>
                                    <div className='enka-card-info-stat__item'>
                                      <i className="icon-chat-two-bubbles-oval"></i> <span>{item?.comments?.length > 0 ? formatNumber(item?.comments?.length) : "Aucun"} commentaire(s)</span>
                                    </div>
                                  </div>
                                  <span className='enka-card-info-stats__action'>Publiée {formatMyDate(item?.createdAt)}</span>
                                </div>
                              </div>
                          </button>
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>
            ) : null
          ) : null
        )
      )
    }
    </>
  )
}

const RecentPosts = () => {
  const {isLoading, isError, isSuccess, posts, message} = useAppSelector((state) => state.post);
  let setting = {
      dot: false,
      infinite: posts?.length > 3 ? true : false,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll:  1,
      variableWidth: true,
      cssEase: 'linear',
      autoplay: posts?.length > 3 ? true : false,
      autoplaySpeed: 2000,
      prevArrow: posts?.length > 3 ? <SliderPrevArrow/> : <></>,
      nextArrow: posts?.length > 3 ? <SliderNextArrow/> : <></>,
  }
  let navigate = useNavigate()
  return (
    <>
    {
      isLoading ? (
        <><i className="icon-spin"></i> <span>Chargement...</span></>
      ) : (
        isError ? (
          <><i className="icon-warning-triangle"></i> <strong>{message}</strong> <button type='button' style={{textDecoration: "underline", opacity: .8}} onClick={() => window?.location?.reload()}>Reactualiser...</button></>
        ) : (
          isSuccess && posts ? (
            posts?.length > 0 ? (
              <div className='enka-home-slide-section'>
                <div className='enka-home-slide-section__header enka-home-slide-section-header'>
                  <h2>Publications recemment postées</h2>
                  <div className='enka-home-slide-section-header__action'>
                    <Link to={links.posts} className='kubini-link'>
                      <span>Voir plus</span>
                    </Link>
                  </div>
                </div>
                <div className='enka-home-slide-section__container'>
                  
                  <Slider {...setting}>
                    {
                      posts?.slice(0, 8).map((item: any, index: number) => (
                        <div className='enka-home-slide-item type2' key={index} style={{
                          marginRight: ".8em"
                        }}>
                          <button className='enka-post' type='button' onClick={() => navigate(links.post + item?.slug)}>
                            {
                              item.epingle ? (
                                <div className='enka-post__important'>
                                  <i className="icon-pin-2"></i>
                                </div>
                              ) : null
                            }
                              <div className='enka-post-info'>
                                <div className='enka-post-info-id'>
                                  <h2>{item?.titre ? truncate(item?.titre, 80) : "ND"}</h2>
                                  <p>{item?.description?.text  ? truncate(item?.description?.text, 80) : "ND"} </p>
                                </div>

                                <div className='enka-post-info-stats'>
                                  <div className='enka-post-info-user'>
                                    <div className='enka-post-info-user__avatar'>
                                    {
                                      item?.user?.auth?.avatar ? (
                                        <img src={item?.user?.auth?.avatar?.url} alt="" />
                                      ) : (
                                        <i className="icon-user-single"></i>
                                      )
                                    }
                                    </div>
                                    <div className='enka-post-info-user__info'>
                                      <strong>{item?.user?.auth?.fullname}</strong>
                                      <span>{item?.user?.city?.name || ""}</span>
                                    </div>
                                  </div>
                                  <div className='enka-card-info-stat'>
                                    <div className='enka-post-info-stat__item'>
                                      <i className="icon-favorite-heart"></i> <span>{item?.likes?.length > 0 ? formatNumber(item?.likes?.length) : "Aucun"} utilisateur(s) aime(nt)</span>
                                    </div>
                                    <div className='enka-post-info-stat__item'>
                                      <i className="icon-chat-two-bubbles-oval"></i> <span>{item?.comments?.length > 0 ? formatNumber(item?.comments?.length) : "Aucun"} commentaire(s)</span>
                                    </div>
                                  </div>
                                  <span className='enka-post-info-stats__action'>Publiée {formatMyDate(item?.createdAt)}</span>
                                </div>
                              </div>
                          </button>
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>
            ) : null
          ) : null
        )
      )
    }
    </>
  )
}


const FavoritesArticles = () => {
  const {isLoading, isError, isSuccess, articles, message} = useAppSelector((state) => state.annonce);
  let setting = {
    dot: false,
    infinite: articles?.filter((el: any) => el.epingle)?.length > 4 ? true : false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    cssEase: 'linear',
    autoplay: articles?.filter((el: any) => el.epingle)?.length > 4 ? true : false,
    autoplaySpeed: 2000,
    prevArrow: articles?.filter((el: any) => el.epingle)?.length > 4 ? <SliderPrevArrow/> : <></>,
    nextArrow: articles?.filter((el: any) => el.epingle)?.length > 4 ? <SliderNextArrow/> : <></>,
  }
  let navigate = useNavigate()
  return (
    <>

    {
      isLoading ? (
        <><i className="icon-spin"></i> <span>Chargement...</span></>
      ) : (
        isError ? (
          <><i className="icon-warning-triangle"></i> <strong>{message}</strong> <button type='button' style={{textDecoration: "underline", opacity: .8}} onClick={() => window?.location?.reload()}>Reactualiser...</button></>
        ) : (
          isSuccess && articles ? (
            articles?.filter((el: any) => el.epingle)?.length > 0 ? (
              <div className='enka-home-slide-section'>
                <div className='enka-home-slide-section__header enka-home-slide-section-header'>
                  <h2>Annonces epinglées</h2>
                  <div className='enka-home-slide-section-header__action'>
                    <Link to={links.articles + "?type=epingle"} className='kubini-link'>
                      <span>Voir plus</span>
                    </Link>
                  </div>
                </div>
                <div className='enka-home-slide-section__container'>
                  
                  <Slider {...setting}>
                    {
                      articles?.filter((el: any) => el.epingle).slice(0, 8).map((item: any, index: number) => (
                        <div className='enka-home-slide-item type2' key={index}>
                         <button className='enka-card' type='button' onClick={() => navigate(links.article + item?.slug)}>
                            {
                              item.epingle ? (
                                <div className='enka-card__important'>
                                  <i className="icon-pin-2"></i>
                                </div>
                              ) : null
                            }
                            <div className='enka-card-image'>
                              {
                                item?.images?.length > 0 ? (
                                  item?.images[0]?.url ? (
                                    <img src={item?.images[0]?.url} alt="cover" />
                                  ) : (
                                    <i className="icon-picture-flower"></i>
                                  )
                                ) : (
                                  <i className="icon-picture-flower"></i>
                                )
                              }
                            </div>
                            <div className='enka-card-info'>
                              <div className='enka-card-info-id'>
                                <strong>{item?.title ? truncate(item?.title, 80) : "ND"}</strong>
                                <h2>{item?.price ? formatNumber2(item.price)+" XOF" : "ND"}</h2>
                                <span>{item?.subcategory?.name + " - " + item?.subcategory?.category?.name?.toUpperCase()}</span>
                              </div>

                              <div className='enka-card-info-stats'>
                                <div className='enka-card-info-user'>
                                  <div className='enka-card-info-user__avatar'>
                                  {
                                    item?.user?.auth?.avatar ? (
                                      <img src={item?.user?.auth?.avatar?.url} alt="" />
                                    ) : (
                                      <i className="icon-user-single"></i>
                                    )
                                  }
                                  </div>
                                  <div className='enka-card-info-user__info'>
                                    <strong>{item?.user?.auth?.fullname}</strong>
                                    <span>{item?.user?.city?.name || ""}</span>
                                  </div>
                                </div>
                                <div className='enka-card-info-stat'>
                                  <div className='enka-card-info-stat__item'>
                                    <i className="icon-favorite-heart"></i> <span>{item?.likes?.length > 0 ? formatNumber(item?.likes?.length) : "Aucun"} utilisateur(s) aime(nt)</span>
                                  </div>
                                  <div className='enka-card-info-stat__item'>
                                    <i className="icon-chat-two-bubbles-oval"></i> <span>{item?.comments?.length > 0 ? formatNumber(item?.comments?.length) : "Aucun"} commentaire(s)</span>
                                  </div>
                                </div>
                                <span className='enka-card-info-stats__action'>Publiée {formatMyDate(item?.createdAt)}</span>
                              </div>
                            </div>
                          </button>
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>
            ) : null
          ) : null
        )
      )
    }
    
    </>
  )
}


const RecentArticles = () => {
  const {isLoading, isError, isSuccess, articles, message} = useAppSelector((state) => state.annonce);
  let navigate = useNavigate()
  return (
    <>
    {
      isLoading ? (
        <><i className="icon-spin"></i> <span>Chargement...</span></>
      ) : (
        isError ? (
          <><i className="icon-warning-triangle"></i> <strong>{message}</strong> <button type='button' style={{textDecoration: "underline", opacity: .8}} onClick={() => window?.location?.reload()}>Reactualiser...</button></>
        ) : (
          isSuccess && articles ? (
            articles?.length > 0 ? (
              <div className='enka-home-grid-section'>
                <div className='enka-home-grid-section__header enka-home-grid-section-header'>
                  <h2>Annonces recemment publiées</h2>
                  <div className='enka-home-grid-section-header__action'>
                    <Link to={links.articles} className='kubini-link'>
                      <span>Voir plus</span>
                    </Link>
                  </div>
                </div>
                <div className='enka-home-grid-grid'>
                  {
                    articles?.slice(0, 32)?.map((item: any, index: number) => (
                      <div className='enka-home-grid-item' key={index}>
                        <button className='enka-card' type='button' onClick={() => navigate(links.article + item?.slug)}>
                          {
                            item.epingle ? (
                              <div className='enka-card__important'>
                                <i className="icon-pin-2"></i>
                              </div>
                            ) : null
                          }
                              <div className='enka-card-image'>
                                {
                                  item?.images?.length > 0 ? (
                                    item?.images[0]?.url ? (
                                      <img src={item?.images[0]?.url} alt="cover" />
                                    ) : (
                                      <i className="icon-picture-flower"></i>
                                    )
                                  ) : (
                                    <i className="icon-picture-flower"></i>
                                  )
                                }
                              </div>
                              <div className='enka-card-info'>
                                <div className='enka-card-info-id'>
                                  <strong>{item?.title ? truncate(item?.title, 80) : "ND"}</strong>
                                  <h2>{item?.price ? formatNumber2(item.price)+" XOF" : "ND"}</h2>
                                  <span>{item?.subcategory?.name + " - " + item?.subcategory?.category?.name?.toUpperCase()}</span>
                                </div>

                                <div className='enka-card-info-stats'>
                                  <div className='enka-card-info-user'>
                                    <div className='enka-card-info-user__avatar'>
                                    {
                                      item?.user?.auth?.avatar ? (
                                        <img src={item?.user?.auth?.avatar?.url} alt="" />
                                      ) : (
                                        <i className="icon-user-single"></i>
                                      )
                                    }
                                    </div>
                                    <div className='enka-card-info-user__info'>
                                      <strong>{item?.user?.auth?.fullname}</strong>
                                      <span>{item?.user?.city?.name || ""}</span>
                                    </div>
                                  </div>
                                  <div className='enka-card-info-stat'>
                                    <div className='enka-card-info-stat__item'>
                                      <i className="icon-favorite-heart"></i> <span>{item?.likes?.length > 0 ? formatNumber(item?.likes?.length) : "Aucun"} utilisateur(s) aime(nt)</span>
                                    </div>
                                    <div className='enka-card-info-stat__item'>
                                      <i className="icon-chat-two-bubbles-oval"></i> <span>{item?.comments?.length > 0 ? formatNumber(item?.comments?.length) : "Aucun"} commentaire(s)</span>
                                    </div>
                                  </div>
                                  <span className='enka-card-info-stats__action'>Publiée {formatMyDate(item?.createdAt)}</span>
                                </div>
                              </div>
                        </button>
                      </div>
                    ))
                  }
                </div>
              </div>
            ) : null
          ) : null
        )
      )
    }
    </>
  )
}

const PartnerSection = () => {
  const {isLoading, isError, isSuccess, partners, message} = useAppSelector((state: any) => state.partner);
  let setting = {
    dot: false,
    infinite: partners?.length > 6 ? true : false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    cssEase: 'linear',
    className: "center",
    autoplay: partners?.length > 6 ? true : false,
    autoplaySpeed: 2000,
    prevArrow: partners?.length > 6 ? <SliderPrevArrow/> : <></>,
    nextArrow: partners?.length > 6 ? <SliderNextArrow/> : <></>,
  }
  return (
    <div className='enka-partners'>
      {
        isLoading ? (
          <span>Chargement</span>
        ) : (
          isError ? (
            <span>Une erreur est survenue: {message}</span>
          ) : (
            isSuccess && partners ? (
              partners?.length > 0 ? (
                <>
                  <div className='enka-partners__header'>
                    <h1>Ils nous font confiance</h1>
                  </div>
                  <div className='enka-partners__container'>
                    <Slider {...setting}>
                      {
                        partners?.map((item: any, index: number) => (
                          <div className='enka-partner-slide-item' key={index}>
                            <a className='enka-partner' href={item.link} target='_blank' rel="noreferrer">
                              {
                                item?.cover ? (
                                    <img
                                        src={item?.cover?.url}
                                        alt={item?.name}/>
                                ) : (
                                    <i className="icon-content-book-phone"/>
                                )
                              }
                              <span>{item?.name}</span>
                            </a>
                          </div>
                        ))
                      }
                    </Slider>
                  </div>
                </>
              ) : null
            ) : null          
          ) 
        )
      }
      
    </div>
  )
}

export const HomePage = () => {
  const dispatch = useAppDispatch();
  const {isLoading, isError, isSuccess, pubs, message} = useAppSelector((state) => state.pub);
  let navigate = useNavigate()
  const {auth} = images
  useEffect(() => {
    dispatch(getArticles({}))
    dispatch(getEvents({}))
    dispatch(getPosts({}))
    dispatch(getPubs({}))
    dispatch(getPartners({}))

    return () => {
      dispatch(reset())
      dispatch(preset())
      dispatch(areset())
      dispatch(ereset())
    }
  }, [dispatch])

  if(isLoading){
    return (
        <div className={"kubini-loader"}>
          <i className="icon-spin"/>
        </div>
    )
  }
  if(isError){
    return (
      <div className={"kubini-user-error"}>
          <div className={"kubini-user-error__container"}>
            <i className="icon-warning-triangle"/>
            <h2>Oupss!! Une erreur est survenue</h2>
            <p>{message}</p>
            <div className={"kubini-user-error__action"}>
              <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
            </div>
          </div>
        </div>
    )
  }

  return (
    <MainLayout>
        <div className='enka-home'>
          {
            isSuccess && pubs ? (
              pubs?.filter((el: any) => el?.type_pub === "home-top")?.length > 0 ? (
                <div  className='enka-ads is--top' >
                  
                  <a href={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.link} target='_blank' rel='noreferrer'>

                  <img src={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.cover?.url} alt="ad" />
                  <span>publicité</span>
                  </a>
                </div>
              ) : null
            ) : null
          }
          <div className='enka-home__banner enka-home-banner'>
            <div className='enka-home-banner__image'>
              <img src={auth} alt="banner" />
            </div>
            <div className='enka-home-banner__container'>
              <h2>Votre marché à votre porte</h2>
              <p>Achetez et vendez vos produits à plusieurs autres personnes rapidement et simplement. Entrer en relation avec de nombreux autres vendeurs et vendeuses</p>
              <div>
                <Button btnType={"button"} label={"Vendre un article"} btnStyle={"is--neutral"} active={true} onClick={() => navigate(links.ajout_article)}/>
              </div>
            </div>
          </div>
          <FavorieEvents/>
          <FavoritesArticles/>
          {
            isSuccess && pubs ? (
              pubs?.filter((el: any) => el?.type_pub === "home-popular")?.length > 0 ? (
                <div  className='enka-ads is--top' >
                  
                  <a href={pubs?.filter((el: any) => el?.type_pub === "home-popular")[0]?.link} target='_blank' rel='noreferrer'>

                  <img src={pubs?.filter((el: any) => el?.type_pub === "home-popular")[0]?.cover?.url} alt="ad" />
                  <span>publicité</span>
                  </a>
                </div>
              ) : null
            ) : null
          }
          <RecentEvents/>
          <RecentPosts/>
          <RecentArticles/>
          {
            isSuccess && pubs ? (
              pubs?.filter((el: any) => el?.type_pub === "home-popular-e")?.length > 0 ? (
                <div  className='enka-ads is--top' >
                  
                  <a href={pubs?.filter((el: any) => el?.type_pub === "home-popular-e")[0]?.link} target='_blank' rel='noreferrer'>

                  <img src={pubs?.filter((el: any) => el?.type_pub === "home-popular-e")[0]?.cover?.url} alt="ad" />
                  <span>publicité</span>
                  </a>
                </div>
              ) : null
            ) : null
          }
          <PartnerSection/>
        </div>
    </MainLayout>
  )
}
